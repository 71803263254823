import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./DetailsPage.scss";
import parse from 'html-react-parser';
import { AltSiteName, AreaguideBaseURL, SaleURL, LetURL } from "../../utils";
import GetCTA from "../../getcta";
import OfficeContactForm from '../../forms/office-contact-form';
import { isMobile } from "react-device-detect";
import HomeBuyingBlock from "./HomeBuyingBlock";
import CheckCircle from "../../../images/CheckCircle.svg"

const OfficeDetailInfo = (props) => {
    const officeitem = props.data;
    const list = officeitem.Office_Stats_List;
    const middleIndex = Math.ceil(list.length / 2);

    const firstHalf = list.splice(0, middleIndex);
    const secondHalf = list.splice(-middleIndex);
    return (
        <React.Fragment>
            <section className="details-page" id="office-details">
                <Container>
                    <Row>
                        <Col>

                            <div className="details-content">
                                <div className="details-wrapper">
                                    <div className="details-info rental-agents">
                                        {parse(officeitem.About_Office)}
                                        {officeitem.Office_Stats_List &&
                                            <div className="stats-block">
                                                <p className="title">{officeitem.Office_Stats_Title}</p>
                                                <div className="content">
                                                    <div className="split">
                                                        <ul>
                                                            {firstHalf.map((list) => {
                                                                return (

                                                                    <li>
                                                                        <img src={CheckCircle} />
                                                                        <div className="list">
                                                                        <strong>{list.Stats}</strong> {list.Stats_Title}
                                                                        </div>
                                                                      
                                                                        </li>

                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className="split">
                                                        <ul>
                                                            {secondHalf.map((list) => {
                                                                return (

                                                                  
                                                                    <li>
                                                                        <img src={CheckCircle} />
                                                                        <div className="list">
                                                                        <strong>{list.Stats}</strong> {list.Stats_Title}
                                                                        </div>
                                                                      
                                                                        </li>

                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>


                                    <div className="bottom-ctas">
                                        <div className="btn-wrapper">
                                            <Link className="btn btn-primary" to={`${SaleURL}/${officeitem.Name.toLowerCase().replace(/ /g, "-")}/`}>Property for sale in {officeitem.Name}</Link>
                                            <Link className="btn btn-primary" to={`${LetURL}/${officeitem.Name.toLowerCase().replace(/ /g, "-")}/`}>Property to let in {officeitem.Name}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-wrapper" id="branch-form-section">
                                    <div className="contact-branch ">
                                        {isMobile ?
                                            <div>
                                                <div className="branch-info" >
                                                    <h2>Contact Branch</h2>
                                                    <OfficeContactForm officeaddress={officeitem.Office_Address} salesphone={officeitem.Sales_Phone_Number} letphone={officeitem.Lettings_Phone_Number} officename={officeitem.Name} salesemail={officeitem.Sales_Email_Address} letemail={officeitem.Lettings_Email_Address} />
                                                </div>
                                                {/* <div className="right-block">
                                                    <div className="appraisal-bar ">
                                                        <div className="left-block">
                                                            <h2>What’s my property worth?</h2>
                                                            <p>
                                                                Selling or Letting? Get a free and up-to-date valuation from one of our experts.
                                                            </p>
                                                        </div>
                                                        <div className="right-block">
                                                            <Link to="/property-valuation/" className="btn">
                                                                Book your market appraisal
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            :
                                            <div>
                                                {/* <div className="right-block">
                                                    <div className="appraisal-bar ">
                                                        <div className="left-block">
                                                            <h2>What’s my property worth?</h2>
                                                            <p>
                                                                Selling or Letting? Get a free and up-to-date valuation from one of our experts.
                                                            </p>
                                                        </div>
                                                        <div className="right-block">
                                                            <Link to="/property-valuation/" className="btn">
                                                                Book your market appraisal
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="branch-info" >
                                                    <h2>Contact Branch</h2>
                                                    <OfficeContactForm officeaddress={officeitem.Office_Address} salesphone={officeitem.Sales_Phone_Number} letphone={officeitem.Lettings_Phone_Number} officename={officeitem.Name} salesemail={officeitem.Sales_Email_Address} letemail={officeitem.Lettings_Email_Address} />
                                                </div>
                                            </div>
                                        }
                                        {/* <HomeBuyingBlock url={officeitem?.URL && `/financial-services/home-buying-app/${officeitem.URL}`} /> */}

                                        {/* {officeitem.Office_Useful_Links ?
                                            <div className="useful-links d-none d-md-block">
                                                <h3>Useful Links</h3>
                                                {officeitem.Office_Useful_Links && officeitem.Office_Useful_Links.map((item) => {
                                                    return <>
                                                        <li>
                                                            {item.Custom_Useful_Link ?
                                                                <Link to={item.Custom_Useful_Link}>{item.Useful_Link_Label}</Link>
                                                                :
                                                                <GetCTA link={item.Useful_Link_Menu && item.Useful_Link_Menu.Primary_URL} Label={item.Useful_Link_Label} />
                                                            }
                                                        </li>
                                                    </>
                                                })}
                                            </div>
                                            : ""} */}
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default OfficeDetailInfo;